import { ServiceCategory } from '../../hooks/apis/use-service-category-api'
import { SzamlazzHuIntegrationSettings } from '../../pages/admin/payments/ConnectSzamlazzHuDialog'
import { Event } from '../events/types'
import { Service } from '../services/types'

export type FeatureFlagName = 'invoicing'

export interface FeatureFlag {
    featureName: FeatureFlagName
    enabled: boolean
}

export type FeatureFlags = Map<FeatureFlagName, FeatureFlag>

export type SubscriptionTier = 'free' | 'pro-trial' | 'pro'
export type BillingCycle = 'monthly' | 'annual'

export enum LocationType {
    Default = 0,
    InPerson = 1,
    Online = 2,
    Hybrid = 3,
}

export enum ButtonStyle {
    Default = 0,
    Square = 1,
    Round = 2,
}

export interface Availability {
    id?: number
    dayOfWeek: number
    workingDay: boolean
    open: string
    close: string
    oddWeek: boolean
    serviceId?: number
    date?: string
}

export interface SocialLink {
    type: string
    link: string
}

export interface CreateBusinessDto {
    name: string
    firstName: string
    lastName: string
    urlHandle: string
    phone: string
    languageCode: string
    timezone: string
}

export interface CustomTheme {
    ThemeColor: string
    InteractivePrimary: string
    InteractivePrimaryHover: string
    InteractivePrimaryActive: string
    InteractiveSecondary: string
    InteractiveSecondaryHover: string
    InteractiveSecondaryActive: string
    FocusOutline: string
    FontColor: string
    BackgroundColor: string
    SurfaceColor: string
    Cover1: string
    Cover2: string
    Cover3: string
    AnimationColor: string
    ButtonStyle: ButtonStyle
    IsDarkTheme: boolean
}

export interface Business {
    id: number
    email: string
    auth0Id: string
    name: string
    firstName: string
    lastName: string
    description: string
    urlHandle: string
    phone: string
    address: string
    link: string
    locationType: LocationType
    isAddressPublic: boolean
    longitude: number
    latitude: number
    serviceType: string
    industry: string
    metaTitle: string
    metaDescription: string
    hasCalendarSync: boolean
    canScheduleFrom: number
    canScheduleUntil: number
    canCancelUntil: number
    phoneRequired: boolean
    services: Service[]
    events: Event[]
    evenOddOpeningWeeks: boolean
    openingHours: Availability[]
    profileImagePublicId?: string
    coverImagePublicId?: string
    ogImagePublicId?: string
    languageCode: string
    countryCode: string
    currencyCode: string
    bookingPageLanguage: string
    timezone: string
    subscriptionTier: SubscriptionTier
    hasPaymentMethod: boolean
    nextInvoiceDate: string
    billingCycle: BillingCycle
    proTrialAvailable: boolean
    eligibleForInitialDiscount: boolean
    trialEndDate?: string
    isCancelPending?: boolean
    isLifetimePro?: boolean
    socialLinks?: SocialLink[]
    theme?: string
    createdAt: string
    customTheme?: CustomTheme
    serviceCategories: ServiceCategory[]
    hideMinupBranding: boolean
    allowMultipleServiceBooking: boolean
    invoicingOn: boolean
    invoicingIntegration?: {
        type: string
        szamlazzHuIntegrationSettings: SzamlazzHuIntegrationSettings
    }
}

export interface AuthState {
    business?: Business
    isLoading: boolean
    isAuthenticated: boolean
    accessToken?: string
    tokenExpiration?: number
    featureFlags?: FeatureFlags
}
