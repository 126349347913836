import { ThemeProvider, keyframes, withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { AdminTheme } from '../../../theme/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { darkTheme } from '../../../theme/dark-theme'
import { Trans } from 'react-i18next'
import { GettingStartedProgress, Guide, Task } from '../../../store/getting-started/types'
import {
    useFinishSubTask,
    useFinishTask,
    useGettingStartedState,
    useSetActiveStep,
    useSkipTask,
    useTaskProgress,
} from '../../../store/getting-started/hooks'
import { classNames } from '../../../services/class-names'
import { useLocation } from 'react-router-dom'
import { useBusiness } from '../../../hooks/use-business'
import { useMatchMedia } from '../../../hooks/use-match-media'
import { useWindowSize } from '../../../hooks/use-window-size'

const FabContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        position: 'fixed',
        bottom: theme.Spacing(2),
        zIndex: 999,
        right: theme.Spacing(2),
        [theme.BreakPoint]: {
            right: theme.Spacing(5),
            bottom: theme.Spacing(5),
        },
        transition: 'transform 100ms linear',
        transform: 'translate(0px, 0px)',
    }))
)

const FabAnimationDuration = '0.3s'

const FabIconShowAnimation = keyframes`
    from {
        opacity: 0;
        transform: rotate(90deg);
    }
    to {
        opacity: 1;
        transform: rotate(0deg);
    }
`
const FabIconHideAnimation = keyframes`
    from {
        opacity: 1;
        transform: rotate(0deg);
    }
    to {
        opacity: 0;
        transform: rotate(90deg);
    }
`
const FabIconShowAnimationX = keyframes`
    from {
        opacity: 0;
        transform: rotate(-90deg);
    }
    to {
        opacity: 1;
        transform: rotate(0deg);
    }
`
const FabIconHideAnimationX = keyframes`
    from {
        opacity: 1;
        transform: rotate(0deg);
    }
    to {
        opacity: 0;
        transform: rotate(-90deg);
    }
`

const FabMenuShowAnimationFromRight = keyframes`
    from {
        transform: scale(0); 
        translate: -25px 25px;
        transform-origin: bottom right;
    }
    to {
        transform: scale(1);
        transform-origin: bottom right
    }
`

const FabMenuShowAnimationFromLeft = keyframes`
    from {
        transform: scale(0); 
        translate: 25px 25px;
        transform-origin: bottom left;
    }
    to {
        transform: scale(1);
        transform-origin: bottom left;
    }
`

const FabMenuHideAnimationFromRight = keyframes`
    from {
        transform: scale(1);
        transform-origin: bottom right;
    }
    to {
        transform: scale(0);
        translate: -25px 25px;
        transform-origin: bottom right;
    }
`

const FabMenuHideAnimationFromLeft = keyframes`
    from {
        transform: scale(1);
        transform-origin: bottom left;
    }
    to {
        transform: scale(0);
        translate: 25px 25px;
        transform-origin: bottom left;
    }
`

const Fab = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        width: 54,
        height: 54,
        borderRadius: '100%',
        backgroundColor: theme.BackgroundColor,
        color: theme.ContentBrand,
        fontSize: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: theme.Elevation_300,
        cursor: 'pointer',
        '.animation-container': {
            display: 'grid',
            alignItems: 'center',
            justifyItems: 'center',
            svg: {
                gridColumn: 1,
                gridRow: 1,
                animationDuration: FabAnimationDuration,
                animationFillMode: 'forwards',
                '&.show': {
                    animationName: FabIconShowAnimation,
                },
                '&.hide': {
                    animationName: FabIconHideAnimation,
                },
                '&.show-x': {
                    animationName: FabIconShowAnimationX,
                },
                '&.hide-x': {
                    animationName: FabIconHideAnimationX,
                },
            },
        },
    }))
)

const FabMenu = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        position: 'absolute',
        bottom: theme.Spacing(8),
        padding: `${theme.Spacing(3)} ${theme.Spacing(2)}`,
        borderRadius: theme.Spacing(1),
        boxShadow: theme.Elevation_300,
        backgroundColor: theme.BackgroundColor,
        color: theme.ContentBrand,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.Spacing(2),
        width: 330,
        animationDuration: FabAnimationDuration,
        animationFillMode: 'forwards',
        '&.left': {
            left: 0,
            '&.show': {
                animationName: FabMenuShowAnimationFromLeft,
            },
            '&.hide': {
                animationName: FabMenuHideAnimationFromLeft,
            },
        },
        '&.right': {
            right: 0,
            '&.show': {
                animationName: FabMenuShowAnimationFromRight,
            },
            '&.hide': {
                animationName: FabMenuHideAnimationFromRight,
            },
        },
        '&.hidden': {
            transform: 'scale(0)',
        },
    }))
)

const PROGRESS_SPEED = 25

const ProgressBar = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        padding: `0 ${theme.Spacing(1)}`,
        gap: theme.Spacing(2),
        display: 'flex',
        alignItems: 'center',
        spacing: theme.Spacing(3),
        color: theme.ContentPrimary,
        ...theme._BodyMediumSemibold,
        '.icon': {
            color: theme.ContentBrand,
        },
        '.progress-bar-container': {
            height: theme.Spacing(2),
            borderRadius: theme.Spacing(2),
            flexGrow: 1,
            backgroundColor: theme.BackgroundSecondary,
            div: {
                borderRadius: theme.Spacing(2),
                background: theme.BackgroundOnboarding,
                height: '100%',
                maxWidth: '100%',
                transition: `width ${PROGRESS_SPEED}ms linear`,
            },
        },
        '.percentage': { minWidth: '40px', textAlign: 'right' },
    }))
)

const TaskList = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    }))
)

const TaskTitle = withTheme(
    styled.div(({ theme, done }: { done: boolean; theme: AdminTheme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: theme.Spacing(1),
        textDecorationLine: done ? 'line-through' : undefined,
        color: done ? theme.ContentDisabled : undefined,
    }))
)

const TaskNumber = withTheme(
    styled.div(({ theme, done }: { done: boolean; theme: AdminTheme }) => ({
        flexShrink: 0,
        borderRadius: '100%',
        backgroundColor: done ? theme.BackgroundSuccessSecondary : theme.BackgroundInfoSecondary,
        color: done ? theme.ContentSuccess : theme.ContentBrand,
        width: theme.Spacing(3),
        height: theme.Spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...theme._BodyMediumSemibold,
    }))
)

const TaskContainer = withTheme(
    styled.div(({ theme, open }: { theme: AdminTheme; open?: boolean }) => ({
        borderRadius: theme.Spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: `${theme.Spacing(2)} ${theme.Spacing(1)}`,
        gap: theme.Spacing(2),
        backgroundColor: open ? theme.InteractiveSecondaryActive : undefined,
        color: open ? theme.ContentPrimary : theme.ContentSecondary,
        ...(open
            ? null
            : {
                  '&:hover': {
                      background: theme.InteractiveSecondaryHover,
                      color: theme.ContentPrimary,
                  },
                  cursor: 'pointer',
              }),
    }))
)

const TaskContentContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        paddingLeft: theme.Spacing(4),
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.Spacing(2),
        ...theme._BodySmall,
    }))
)

const SubTaskList = withTheme(
    styled.ul(({ theme }: { theme: AdminTheme }) => ({
        paddingLeft: theme.Spacing(2),
        margin: 0,
        'li + li': {
            marginTop: theme.Spacing(2),
        },
        'li.done': {
            color: theme.ContentSecondary,
            textDecorationLine: 'line-through',
        },
        li: {
            a: {
                color: theme.InteractivePrimary,
                textDecoration: 'underline',
                '&:hover': {
                    color: theme.InteractivePrimaryHover,
                },
                '&:active': {
                    color: theme.InteractivePrimaryActive,
                },
            },
        },
    }))
)

const SkipTaskButton = withTheme(
    styled.button(({ theme }: { theme: AdminTheme }) => ({
        padding: 0,
        border: 'none',
        background: 'none',
        color: theme.ContentTertiary,
        ...theme._BodyMedium,
        cursor: 'pointer',
        flexGrow: 0,
    }))
)

const GuideTask: FC<{ task: Task; open: boolean }> = ({ task, open }) => {
    const setActiveStep = useSetActiveStep()
    const skipTask = useSkipTask()
    const onOpen = useCallback(() => {
        !open && setActiveStep(task.id)
    }, [open, setActiveStep, task.id])
    const onSkip = useCallback(() => {
        skipTask(task.id)
    }, [skipTask, task.id])

    const progress = useTaskProgress(task.id)
    const done = progress.done || progress.skipped

    const finishTask = useFinishTask()
    useEffect(() => {
        if (!progress.done && task.autocomplete) {
            finishTask(task.id)
        }
    }, [finishTask, task.autocomplete, progress.done, task.id])

    const finishSubTask = useFinishSubTask()
    const location = useLocation()
    const business = useBusiness()
    const bookingPageUrl = `/book/${encodeURIComponent(business?.urlHandle || '')}`
    useEffect(() => {
        if (
            open &&
            (location.pathname === task.linkTo ||
                (task.linkTo === 'bookingpage' && location.pathname === bookingPageUrl))
        ) {
            const subTask = task.subTasks.length ? task.subTasks[0] : undefined

            if (subTask && subTask.id.startsWith('GO_TO_') && !progress.completedSubTasks.includes(subTask.id)) {
                finishSubTask(task.id, [subTask.id])
            }
        }
    }, [
        bookingPageUrl,
        finishSubTask,
        location,
        location.pathname,
        open,
        progress.completedSubTasks,
        task.id,
        task.linkTo,
        task.subTasks,
    ])

    return (
        <TaskContainer key={task.id} open={open} onClick={onOpen}>
            <TaskTitle done={done}>
                <TaskNumber done={done}>{done ? <FontAwesomeIcon icon={faCheck} /> : task.number}</TaskNumber>
                {task.title}
            </TaskTitle>
            {open && (task.subTasks?.length > 0 || task.note !== undefined) && (
                <TaskContentContainer>
                    {task.subTasks !== undefined && (
                        <SubTaskList>
                            {task.subTasks.map((subTask, idx) => (
                                <li
                                    className={classNames(
                                        progress.completedSubTasks?.includes(subTask.id) ? 'done' : null
                                    )}
                                    key={subTask.id}
                                >
                                    {subTask.title}
                                </li>
                            ))}
                        </SubTaskList>
                    )}
                    {task.note !== undefined && <div>{task.note}</div>}
                    {!done && (
                        <SkipTaskButton type="button" onClick={onSkip}>
                            <Trans ns="admin">Skip</Trans>
                        </SkipTaskButton>
                    )}
                </TaskContentContainer>
            )}
        </TaskContainer>
    )
}

const FabBadge = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        animationFillMode: 'forwards',
        animationDuration: FabAnimationDuration,
        '&.show': {
            animationName: FabMenuShowAnimationFromRight,
        },
        '&.hide': {
            animationName: FabMenuHideAnimationFromRight,
        },
        height: 28,
        width: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'var(--Gradiants-onboarding-light, linear-gradient(270deg, #3746FF 0%, #16ADD1 100%))',
        color: theme.ContentPrimary,
        borderRadius: 14,
        boxShadow: theme.Elevation_300,
        ...theme._BodyMediumSemibold,
        translate: '14px 10px',
    }))
)

export const CLOSE_GETTING_STARTED_PREFIX = 'CLOSE_GETTING_STARTED_'
export const GettingStartedGuide: FC<{ guide: Guide; progress: GettingStartedProgress | undefined }> = ({
    guide,
    progress,
}) => {
    const initialOpen = useMemo(
        () => (localStorage.getItem(CLOSE_GETTING_STARTED_PREFIX + guide.id) === 'closed' ? false : true),
        [guide.id]
    )
    const [diff, setDiff] = useState({ x: 0, y: 0 })
    const [open, setOpen] = useState(initialOpen ? true : undefined)
    const [currentProgress, setCurrentProgress] = useState(progress?.progress || 0)
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })
    useWindowSize(setWindowSize)

    const origin = useMemo<{ x: number; y: number }>(
        () => ({
            x: windowSize.width - 50,
            y: windowSize.height - 42,
        }),
        [windowSize.height, windowSize.width]
    )
    const toggleOpenClose = useCallback(() => {
        setOpen((open) => {
            const newValue = !open
            localStorage.setItem(CLOSE_GETTING_STARTED_PREFIX + guide.id, newValue ? 'opened' : 'closed')

            return newValue
        })
    }, [guide.id])

    useEffect(() => {
        if (progress?.progress && currentProgress < progress?.progress) {
            setTimeout(() => {
                setCurrentProgress(currentProgress + 1)
            }, PROGRESS_SPEED + 5)
        }
    }, [currentProgress, progress?.progress])

    const [moving, setMoving] = useState(false)
    const location = useLocation()

    const onCalendar = useMemo(() => location.pathname.includes('calendar'), [location.pathname])
    const smallScreen = useMatchMedia('(max-width: 820px)')
    const onLeft = useMemo<boolean>(() => onCalendar && smallScreen, [onCalendar, smallScreen])
    const [fabMenuPosition, setFabMenuPosition] = useState<'left' | 'right'>(onLeft ? 'left' : 'right')

    const onTouchStart = useCallback(
        (e: React.TouchEvent) => {
            if (open) {
                return
            }
            setMoving(true)
        },
        [open]
    )

    const onTouchMove = useCallback(
        (e: React.TouchEvent) => {
            if (moving && origin) {
                setDiff({ x: origin.x - e.touches[0].clientX, y: origin.y - e.touches[0].clientY })
            }
        },
        [moving, origin]
    )

    const onTouchEnd = useCallback(
        (e: React.TouchEvent) => {
            setMoving(false)
            if (diff.x < windowSize.width / 2 && !onLeft) {
                setDiff((diff) => ({ ...diff, x: 6 }))
            } else {
                setDiff((diff) => ({ ...diff, x: windowSize.width - 92 }))
            }
        },
        [diff.x, onLeft, windowSize.width]
    )

    useEffect(() => {
        if (onLeft) {
            setDiff((diff) => ({ ...diff, x: windowSize.width - 92 }))
            if (open) {
                setFabMenuPosition('left')
            }
        } else {
            setDiff((diff) => ({ ...diff, x: 6 }))
            if (open) {
                setFabMenuPosition('right')
            }
        }
    }, [initialOpen, onLeft, open, windowSize.width])

    const [positioning, setPositioning] = useState<CSSProperties>()
    useEffect(() => {
        const x = Math.max(92 - windowSize.width, Math.min(6, -diff.x))
        if (open) {
            setPositioning(smallScreen ? { transform: `translate(${x}px, -16px)` } : undefined)
        } else {
            const y = Math.max(120 - windowSize.height, Math.min(-16, -diff.y))
            setPositioning({
                transform: smallScreen ? `translate(${x}px, ${y}px)` : `translate(0px, 0px)`,
            })
        }
    }, [diff.x, diff.y, onCalendar, open, smallScreen, windowSize.height, windowSize.width])

    return guide ? (
        <ThemeProvider theme={darkTheme}>
            <FabContainer
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                style={positioning}
            >
                <FabMenu
                    className={classNames(
                        open === undefined ? null : open ? 'show' : 'hide',
                        initialOpen ? null : 'hidden',
                        fabMenuPosition === 'left' ? 'left' : 'right'
                    )}
                >
                    <ProgressBar>
                        <FontAwesomeIcon className="icon" icon={guide.icon} />
                        <div className="progress-bar-container">
                            <div style={{ width: `${currentProgress}%` }}></div>
                        </div>
                        <span className="percentage">{currentProgress}%</span>
                    </ProgressBar>
                    <TaskList>
                        {guide.tasks.map((task) => (
                            <GuideTask key={task.id} task={task} open={task.id === progress?.activeTask} />
                        ))}
                    </TaskList>
                </FabMenu>
                <FabBadge className={open ? 'hide' : 'show'}>{currentProgress}%</FabBadge>
                <Fab onClick={toggleOpenClose}>
                    <div className="animation-container">
                        <FontAwesomeIcon className={open ? 'hide' : 'show'} icon={guide.icon} />
                        <FontAwesomeIcon className={open ? 'show-x' : 'hide-x'} icon={faTimes} />
                    </div>
                </Fab>
            </FabContainer>
        </ThemeProvider>
    ) : null
}

export const GettingStartedGuides: FC = () => {
    const { currentGuide, progress } = useGettingStartedState()
    return currentGuide ? <GettingStartedGuide key={currentGuide.id} guide={currentGuide} progress={progress} /> : null
}
