import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Flex } from '../../../../components/helpers/Flex'
import { Accordion } from '../../../../components/ui-kit/comopnents/Accordion'
import { Badge } from '../../../../components/ui-kit/comopnents/Badge'
import { StyledTooltip } from '../../../../components/ui-kit/comopnents/StyledTooltip'
import { useSubscriptionApi } from '../../../../hooks/apis/use-subscription-api'
import { AdminTheme } from '../../../../theme/theme'
import { LoadingScreen } from '../../../../components/ui-kit/comopnents/LoadingScreen'
import { useCancelSubscription } from '../hooks/use-cancel-subscription'
import { useFormatCurrency } from '../../../../hooks/use-format-currency'
import { useBusiness } from '../../../../hooks/use-business'
import { LocalDate } from '../../../../components/helpers/LocalDate'

export enum InvoiceStatus {
    Pending = 0,
    PaymentPending = 1,
    Successful = 2,
    CancellationRequested = 3,
    Cancelled = 4,
    RetryPending = 5,
    Failed = 6,
}

export interface Invoice {
    status: InvoiceStatus
    paymentDate: string
    amount: number
    netAmount: number
    vatPercent: number
    invoiceLink?: string
    isBackground?: boolean
}

const CancelButton = withTheme(
    styled('button')(({ theme }: { theme: AdminTheme }) => ({
        color: theme.Secondary4_700,
        border: 'none',
        outline: 'none',
        background: 'none',
        cursor: 'pointer',
        padding: 0,
        '&:hover': {
            color: theme.Secondary4_800,
        },
    }))
)

const InvoiceTable = withTheme(
    styled('table')(({ theme }: { theme: AdminTheme }) => ({
        borderSpacing: 0,
        width: '100%',
        th: {
            textAlign: 'start',
            borderBottom: `1px solid ${theme.Neutral_100}`,
            ...theme._BodySmallSemibold,
        },
        'td, th': {
            padding: '16px 24px',
            width: 'auto',
            whiteSpace: 'nowrap',
        },
        'td:last-of-type': {
            width: '100%',
        },
    }))
)
export const InvoiceHistory: FC = () => {
    const [loading, setLoading] = useState(true)
    const { listInvoices } = useSubscriptionApi()
    const [invoices, setInvoices] = useState([] as Invoice[])
    const { t } = useTranslation('admin')
    const onCancel = useCancelSubscription()
    const { billingCycle, languageCode } = useBusiness()!

    useEffect(() => {
        ;(async () => {
            const response = await listInvoices()
            if (response.data) {
                setInvoices(
                    response.data.filter(
                        (invoice) =>
                            invoice.isBackground ||
                            invoice.amount > 0 ||
                            (invoice.status !== InvoiceStatus.PaymentPending &&
                                invoice.status !== InvoiceStatus.Cancelled)
                    )
                )
            }
            setLoading(false)
        })()
    }, [listInvoices, billingCycle])

    const formatCurrency = useFormatCurrency(languageCode)

    return (
        <Accordion title={<Trans ns="admin">Invoice history</Trans>}>
            {loading ? (
                <div style={{ position: 'relative', minHeight: '100px' }}>
                    <LoadingScreen />
                </div>
            ) : invoices.length ? (
                <div style={{ overflowY: 'auto' }}>
                    <InvoiceTable>
                        <thead>
                            <tr>
                                <th>
                                    <Trans ns="admin">Date</Trans>
                                </th>
                                <th>
                                    <Trans ns="admin">Net Price</Trans>
                                </th>
                                <th>
                                    <Trans ns="admin">VAT</Trans>
                                </th>
                                <th>
                                    <Trans ns="admin">Total Price</Trans>
                                </th>
                                <th>
                                    <Trans ns="admin">Status</Trans>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.map((invoice, idx) => (
                                <tr key={idx}>
                                    <td>
                                        <StyledTooltip
                                            mouseEnterDelay={2}
                                            text={moment(invoice.paymentDate).format(t('date_time_full'))}
                                        >
                                            <span>
                                                <LocalDate dateTime={invoice.paymentDate} />
                                            </span>
                                        </StyledTooltip>
                                    </td>
                                    <td>{formatCurrency(invoice.netAmount)}</td>
                                    <td>{invoice.vatPercent}%</td>
                                    <td>{formatCurrency(invoice.amount)}</td>
                                    <td>
                                        <Flex justifyContent="flex-start">
                                            <Badge
                                                variant={
                                                    invoice.status === InvoiceStatus.Successful
                                                        ? 'Success'
                                                        : invoice.status === InvoiceStatus.Failed ||
                                                            invoice.status === InvoiceStatus.CancellationRequested ||
                                                            invoice.status === InvoiceStatus.Cancelled
                                                          ? 'Danger'
                                                          : invoice.status === InvoiceStatus.RetryPending
                                                            ? 'Warning'
                                                            : 'Info'
                                                }
                                            >
                                                {t(`invoice_status_${invoice.status}`)}
                                            </Badge>
                                        </Flex>
                                    </td>
                                    <td>
                                        {invoice.status === InvoiceStatus.Pending ? (
                                            <CancelButton onClick={onCancel}>
                                                <Trans ns="admin">Cancel subscription</Trans>
                                            </CancelButton>
                                        ) : invoice.invoiceLink ? (
                                            <a
                                                className="small"
                                                target="_blank"
                                                rel="noreferrer"
                                                href={decodeURIComponent(invoice.invoiceLink)}
                                            >
                                                <Trans ns="admin">View invoice</Trans>
                                            </a>
                                        ) : null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </InvoiceTable>
                </div>
            ) : (
                <div>
                    <Trans ns="admin">You have no invoices yet.</Trans>
                </div>
            )}
        </Accordion>
    )
}
