import DOMPurify from 'dompurify'
import { Moment } from 'moment'
import { FC, HTMLAttributes, useState, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { Flex } from '../../../components/helpers/Flex'
import { UtcTimestamp } from '../../../components/helpers/UtcTimestamp'
import { Business } from '../../../store/auth/types'
import { Service } from '../../../store/services/types'
import { useClientTimezone } from '../../../hooks/useClientTimezone'
import { getImageUrl } from '../../../services/get-image-url'
import { GoogleMapsLink } from '../../../components/ui-kit/comopnents/GoogleMapsLink'
import { ServiceDetailsContainer, SummaryCardContainer } from '../components/SummaryCardContainer'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'
import styled from '@emotion/styled'
import {
    AvatarImage,
    ExtraImageContainer,
    ServiceAvatarContainer,
} from '../../../components/ui-kit/comopnents/AvatarImage'
import { Slot } from './ServicePage'
import { TextButton } from '../../../components/ui-kit/button/TextButton'
import { LONG_MONTH_AND_DAY_FORMAT } from '../dictionaries/constants'
import { Currency } from '../../../components/helpers/Currency'
import { asClickable } from '../../../components/helpers/as-clickable'
import { SummaryListPopup } from './SummaryListPopup'
import i18n from '../../../i18n'
import { DefaultAvatar } from '../../../components/ui-kit/comopnents/Avatar'
import { useFormatMinutes } from '../../../hooks/use-format-minutes'
import { UcFirst } from '../components/UcFirst'

const ShowDetailsButton: FC<{ expand: boolean; setExpand: (value: boolean) => void }> = ({ expand, setExpand }) => {
    return (
        <TextButton
            className="show-hide-button"
            onClick={(e) => {
                e.preventDefault()
                setExpand(!expand)
            }}
        >
            {expand ? <Trans ns="bookingpage">Hide details</Trans> : <Trans ns="bookingpage">Show details</Trans>}
        </TextButton>
    )
}

const DashedBorderedSpan = asClickable(
    withTheme(
        styled.span(({ theme }: { theme: AdminTheme }) => ({
            borderBottomStyle: 'dashed',
            borderBottomColor: theme.BroderSecondary,
            borderBottomWidth: '1px',
        }))
    )
)

export interface SummaryCardProps {
    hideDetails?: boolean
    service: Service
    additionalServices: Service[]
    day?: Moment
    slot?: Slot
    business: Business
    success?: boolean
}

export const SummaryCard: FC<Omit<HTMLAttributes<HTMLDivElement>, 'slot'> & SummaryCardProps> = ({
    hideDetails,
    service,
    additionalServices,
    slot,
    business,
    success,
    ...rest
}) => {
    const [expand, setExpand] = useState(false)
    const description = useMemo(() => {
        const purified = DOMPurify.sanitize(service.description || '')
        const test = document.createElement('span')
        test.innerHTML = purified
        return test.textContent?.trim() ? purified : ''
    }, [service.description])
    const { clientTimezone } = useClientTimezone()
    const imageUrl = getImageUrl(service.imagePublicId, 'bookingpageservice', DefaultAvatar)
    const extraImageUrl = additionalServices?.length
        ? getImageUrl(additionalServices[0].imagePublicId, 'bookingpageservice', DefaultAvatar)
        : undefined
    const [modalOpen, setModalOpen] = useState(false)
    const formatMinutes = useFormatMinutes()

    const summary = useMemo(() => {
        if (additionalServices.length === 0) {
            return (
                <Flex flexDirection="column" alignItems="flex-start" gap={1}>
                    <h3>{service.name}</h3>
                    <span>
                        {formatMinutes(service.duration) + (service.priceText ? ' | ' + service.priceText : '')}
                    </span>
                </Flex>
            )
        } else {
            let sumDuration = 0
            let sumPrice = 0
            const selectedServices = [service, ...additionalServices]
            for (const service of selectedServices) {
                sumDuration += service.duration
                sumPrice += service.priceValue || 0
            }

            return (
                <Flex flexDirection="column" alignItems="flex-start" gap={1}>
                    <h3>
                        <DashedBorderedSpan onClick={() => setModalOpen(true)}>
                            <Trans ns="bookingpage">{{ n: selectedServices.length }} services</Trans>
                        </DashedBorderedSpan>
                    </h3>
                    <span>
                        {formatMinutes(sumDuration)}{' '}
                        {sumPrice > 0 ? (
                            <>
                                |{' '}
                                <Currency
                                    value={sumPrice}
                                    currency={business.currencyCode}
                                    languageCode={i18n.language}
                                />
                            </>
                        ) : null}
                    </span>
                </Flex>
            )
        }
    }, [additionalServices, business.currencyCode, formatMinutes, service])

    return (
        <SummaryCardContainer {...rest}>
            <div className="details-container">
                <ServiceAvatarContainer>
                    {extraImageUrl ? (
                        <ExtraImageContainer>
                            <AvatarImage
                                src={extraImageUrl}
                                emojiSize={20}
                                renderImage={(src) => <img src={src} alt="" title="" />}
                            />
                        </ExtraImageContainer>
                    ) : null}
                    <AvatarImage
                        src={imageUrl}
                        emojiSize={24}
                        renderImage={(src) => <img src={src} alt="" title="" />}
                    />
                </ServiceAvatarContainer>
                <div className="service-summary">{summary}</div>
                {(slot || service.address) && !hideDetails ? (
                    <div className="details-summary">
                        <Flex flexDirection="column" alignItems="flex-start" gap={1}>
                            {slot ? (
                                <Flex className="bodymedium semibold" alignItems="flex-start">
                                    <span className="mrs">🗓️</span>
                                    <UcFirst>
                                        <UtcTimestamp
                                            tz={clientTimezone}
                                            format={LONG_MONTH_AND_DAY_FORMAT}
                                            timestamp={slot.from}
                                        />{' '}
                                        <UtcTimestamp tz={clientTimezone} format="HH:mm" timestamp={slot.from} />
                                        -
                                        <UtcTimestamp tz={clientTimezone} format="HH:mm" timestamp={slot.to} />
                                    </UcFirst>
                                </Flex>
                            ) : null}
                            {service.address ? (
                                <GoogleMapsLink className="only-desktop" address={service.address}>
                                    {service.address}
                                </GoogleMapsLink>
                            ) : null}
                        </Flex>
                    </div>
                ) : null}
            </div>

            {!hideDetails ? (
                <>
                    <div className="only-desktop">
                        {description ? (
                            <ServiceDetailsContainer
                                flexDirection="column"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                            >
                                <ShowDetailsButton expand={expand} setExpand={setExpand} />
                                {expand ? <p dangerouslySetInnerHTML={{ __html: description }} /> : null}
                            </ServiceDetailsContainer>
                        ) : null}
                    </div>

                    <div className="only-mobile">
                        {description || service.address ? (
                            <ServiceDetailsContainer
                                flexDirection="column"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                            >
                                <ShowDetailsButton expand={expand} setExpand={setExpand} />
                                {expand ? (
                                    <>
                                        {service.address ? (
                                            <div className="details-summary">
                                                <GoogleMapsLink className="only-mobile" address={service.address}>
                                                    {service.address}
                                                </GoogleMapsLink>
                                            </div>
                                        ) : null}
                                        {description && <p dangerouslySetInnerHTML={{ __html: description }} />}
                                    </>
                                ) : null}
                            </ServiceDetailsContainer>
                        ) : null}
                    </div>
                </>
            ) : null}
            {modalOpen ? (
                <SummaryListPopup
                    services={[service, ...additionalServices]}
                    business={business}
                    onClose={() => setModalOpen(false)}
                />
            ) : null}
        </SummaryCardContainer>
    )
}
