import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FC } from 'react'
import { AdminTheme } from '../../../theme/theme'
import { AdminBannerContainer } from '../layout/AdminBannerContainer'
import { Icon } from '../../ui-kit/comopnents/Icon'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Flex } from '../../helpers/Flex'
import moment from 'moment'
import { Business } from '../../../store/auth/types'

const WarningBanner = withTheme(
    styled(AdminBannerContainer)(({ theme }: { theme: AdminTheme }) => ({
        backgroundColor: theme.BackgroundWarningSecondary,
    }))
)

export const PaymentMethodBanner: FC<{ business: Business }> = ({ business }) => {
    const { t } = useTranslation('admin')
    const date = moment(business.nextInvoiceDate).format(t('date_format_full'))
    return (
        <WarningBanner>
            <Flex
                flexDirection="column"
                desktopDirection="row"
                flexWrap="wrap"
                gap={1}
                rowGap={2}
                alignItems="flex-start"
                desktopJustifyContent="flex-start"
                desktopAlignItems="center"
            >
                <Flex gap={1}>
                    <Icon icon={faExclamationTriangle} variant="ContentWarning" />
                    <b>
                        <Trans ns="admin">Important:</Trans>
                    </b>
                </Flex>
                <span>
                    <Trans ns="admin">
                        Update your payment card by <b>{{ date }}</b>, to avoid service interruption.
                    </Trans>
                </span>
                <NavLink to={'/admin/subscription#payment-method'} className={'underlined'}>
                    <Trans ns="admin">Update payment card</Trans>
                </NavLink>
            </Flex>
        </WarningBanner>
    )
}
