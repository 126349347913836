import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { AdminActionTypes } from './actions'
import { Action2, createAction2 } from '../utils/create-action'
import { eventChannel } from 'redux-saga'
import i18n, { AVAILABLE_LANGUAGES, LanguageCodes } from '../../i18n'
import { AdminState, ZendeskOptions } from './types'
import { AuthState } from '../auth/types'
import { defaultTheme } from '../../theme/default-theme'
import { AuthActionTypes, UpdateBusinessAction } from '../auth/actions'

const ZENDESK_SCRIPT_ID = 'ze-snippet'

function createZendeskEventChannel() {
    return eventChannel<Action2>((emitter) => {
        const script = document.createElement('script')
        document.body.appendChild(script)
        const onload = () => {
            if (window.zE) {
                emitter(createAction2({ type: AdminActionTypes.ZENDESK_SCRIPT_LOADED }))
                // Hide launcher initially
                window.zE('webWidget', 'hide')
                // Subscribe to widget opened event
                window.zE('webWidget:on', 'userEvent', (e: any) => {
                    if (e?.action === 'Web Widget Opened') {
                        emitter(createAction2({ type: AdminActionTypes.SET_ZENDESK_WIDGET_LOADING, payload: false }))
                        emitter(createAction2({ type: AdminActionTypes.SET_ADMIN_MENU_OPEN, payload: false }))
                    }
                })
                // Subscribe to widget close event
                window.zE('webWidget:on', 'close', () => {
                    window.zE('webWidget', 'hide')
                    emitter(createAction2({ type: AdminActionTypes.SET_WIDGET_OPEN, payload: false }))
                })
            } else {
                console.error('window.zE is not available.')
            }
        }
        script.onload = onload
        script.id = ZENDESK_SCRIPT_ID
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_SNIPPET_KEY}`
        script.async = true

        script.onerror = () => {
            console.error('Failed to load Zendesk script.')
        }
        return () => {
            script.removeEventListener('load', onload)
        }
    })
}

function* loadZendeskSaga(): any {
    if (process.env.REACT_APP_ZENDESK_SNIPPET_KEY) {
        try {
            let loaded = false
            do {
                // wait for loading business and load zendesk script after
                const action: UpdateBusinessAction = yield take(AuthActionTypes.UPDATE_BUSINESS)
                if (action.payload?.email) {
                    const channel = createZendeskEventChannel()
                    yield takeEvery(channel, function* (action: Action2) {
                        yield put(action)
                    })
                    loaded = true
                }
            } while (!loaded)
        } catch (e) {
            console.error(e)
        }
    }
}

function* setZendeskSettings({ name, email, language }: ZendeskOptions): any {
    const { zendeskScriptLoaded } = yield select((state: { admin: AdminState }) => state.admin)
    if (zendeskScriptLoaded && window.zE) {
        window.zE(
            'webWidget',
            'setLocale',
            language && AVAILABLE_LANGUAGES.includes(language) ? language : LanguageCodes.En
        )
        // Set suggestions
        window.zE('webWidget', 'helpCenter:setSuggestions', {
            search: 'basics',
        })

        // Set identification
        window.zE('webWidget', 'identify', {
            name: name || '',
            email: email || '',
        })

        window.zE('webWidget', 'updateSettings', {
            webWidget: {
                offset: { horizontal: '300px', vertical: '0' },
                position: { horizontal: 'left', vertical: 'bottom' },
                color: {
                    button: defaultTheme.Primary_800,
                    header: defaultTheme.Primary_800,
                },
                answerBot: {
                    title: {
                        '*': i18n.t('Minup help', { ns: 'admin' }),
                    },
                },
            },
        })
    }
}

function* updateZendeskSettingsSaga() {
    try {
        // Set zendesk options after script is loaded or language is changed
        yield takeEvery(
            [AuthActionTypes.UPDATE_BUSINESS_LANGUAGE, AdminActionTypes.ZENDESK_SCRIPT_LOADED],
            function* () {
                const { name, email, languageCode } = yield select(
                    (state: { auth: AuthState }) =>
                        state.auth.business || { name: '', email: '', languageCode: LanguageCodes.En }
                )
                yield call(setZendeskSettings, {
                    name,
                    email,
                    language: languageCode,
                })
            }
        )
    } catch (e) {
        console.error(e)
    }
}

function* toggleZendeskWidgetSaga() {
    try {
        yield takeEvery(AdminActionTypes.TOGGLE_ZENDESK_WIDGET, function* () {
            const { widgetOpen, zendeskScriptLoaded } = yield select((state: { admin: AdminState }) => state.admin)
            if (zendeskScriptLoaded && window.zE) {
                yield put(createAction2({ type: AdminActionTypes.SET_WIDGET_OPEN, payload: !widgetOpen }))
                if (widgetOpen) {
                    window.zE('webWidget', 'close')
                    window.zE('webWidget', 'hide')
                } else {
                    yield put(createAction2({ type: AdminActionTypes.SET_ZENDESK_WIDGET_LOADING, payload: true }))
                    window.zE('webWidget', 'show')
                    window.zE('webWidget', 'open')
                }
            }
        })
    } catch (e) {
        console.error(e)
    }
}

export function* zendeskRootSaga() {
    yield fork(loadZendeskSaga)
    yield fork(updateZendeskSettingsSaga)
    yield fork(toggleZendeskWidgetSaga)
}
