import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'

export function useFormatMinutes() {
    const { t } = useTranslation('admin')
    return useCallback(
        (minutes: number) => {
            const hours = Math.floor(minutes / 60)
            const mins = minutes % 60
            const hoursText = hours > 0 ? t('{{count}} formatMinutes-hours', { count: hours }) + ' ' : ''
            const minutesText = mins > 0 ? t('{{count}} formatMinutes-minutes', { count: mins }) : ''

            return hoursText + minutesText
        },
        [t]
    )
}
