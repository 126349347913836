import { FC, useMemo } from 'react'
import { useClientTimezone } from '../../../hooks/useClientTimezone'
import { OccurrencePublicDto } from '../../../store/events/types'
import { Slot } from '../services/ServicePage'
import { Flex } from '../../../components/helpers/Flex'
import { UtcTimestamp } from '../../../components/helpers/UtcTimestamp'
import { GoogleMapsLink } from '../../../components/ui-kit/comopnents/GoogleMapsLink'
import moment from 'moment'
import { LONG_MONTH_AND_DAY_FORMAT } from '../dictionaries/constants'
import { DetailsCardContainer } from './DetailsCardContainer'
import { UcFirst } from './UcFirst'

interface CommonProps {
    name: string
    location?: string
}
interface EventCardProps {
    type?: 'event'
    selectedSlot?: OccurrencePublicDto
}

interface ServiceCardProps {
    type?: 'service'
    selectedSlot?: Slot
}

type DetailsCardProps = EventCardProps | ServiceCardProps

export const DetailsCard: FC<DetailsCardProps & CommonProps> = ({ type, selectedSlot, name, location }) => {
    const { clientTimezone } = useClientTimezone()
    const [from, to] = useMemo(() => {
        return type === 'service'
            ? [selectedSlot?.from!, selectedSlot?.to!]
            : [moment(selectedSlot?.from).unix(), moment(selectedSlot?.to).unix()]
    }, [selectedSlot?.from, selectedSlot?.to, type])

    return (
        <DetailsCardContainer spacing={1}>
            {type ? (
                <>
                    <span className="bodymedium semibold">{name}</span>
                    <Flex className="bodymedium semibold" justifyContent="flex-start">
                        <span className="mrs">🗓️</span>
                        <UcFirst>
                            <UtcTimestamp tz={clientTimezone} format={LONG_MONTH_AND_DAY_FORMAT} timestamp={from} />{' '}
                            <UtcTimestamp tz={clientTimezone} format="HH:mm" timestamp={from} />
                            -
                            <UtcTimestamp tz={clientTimezone} format="HH:mm" timestamp={to} />
                        </UcFirst>
                    </Flex>
                    {location ? <GoogleMapsLink address={location}>{location}</GoogleMapsLink> : null}
                </>
            ) : null}
        </DetailsCardContainer>
    )
}
