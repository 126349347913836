import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme, fromTheme } from '../../../theme/theme'
import { Input } from './Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ComponentProps, CSSProperties, forwardRef, RefObject, useMemo } from 'react'
import { useAdminTheme } from '../../../hooks/use-admin-theme'

const DropDownOptionListUl = withTheme(styled.ul`
    box-sizing: border-box;
    position: absolute;
    top: 105%;
    width: 100%;
    background: ${fromTheme('SurfaceColor')};
    border-radius: 8px;
    border: 1px solid ${fromTheme('BorderPrimary')};
    list-style-type: none;
    margin-top: 0;
    padding: 5px 0;
    z-index: 9;
    max-height: 268px;
    overflow-y: auto;
    box-shadow: ${fromTheme('Elevation_200')};
    scrollbar-width: auto;
    & > li {
        padding: 8px 16px;
        cursor: pointer;
        border-radius: 8px;
        &:hover {
            background: ${fromTheme('InteractiveNeutralHover')};
        }
        &:active {
            background: ${fromTheme('InteractiveNeutralActive')};
        }
        &.no-match {
            background: none !important;
            cursor: default;
            color: ${fromTheme('Neutral_500')};
        }
    }
`)

export const DropDownOptionList = forwardRef<
    HTMLUListElement,
    {
        inputRef?: RefObject<HTMLElement | undefined>
        dropdownMaxHeight?: number
        dropdownWidth?: number
        fixedPosition?: boolean
    } & ComponentProps<typeof DropDownOptionListUl>
>(({ children, inputRef, dropdownWidth, dropdownMaxHeight = 260, fixedPosition, style, ...rest }, ref) => {
    const theme = useAdminTheme()
    const dropdownStyle = useMemo<CSSProperties | undefined>(() => {
        let props = {
            ...style,
        }
        if (inputRef?.current) {
            const bodyRect = document.body.getBoundingClientRect(),
                elemRect = inputRef.current.getBoundingClientRect(),
                offset = elemRect.top - bodyRect.top + elemRect.height + 5,
                positionFromBottom = window.innerHeight - elemRect.bottom,
                bottomPadding = theme.SpacingValue(3)

            let positioning: CSSProperties = {}
            if (fixedPosition) {
                if (dropdownMaxHeight + bottomPadding > positionFromBottom) {
                    positioning = { bottom: positionFromBottom + inputRef.current.clientHeight - 8, top: 'unset' }
                } else {
                    positioning = { top: Math.min(offset, bodyRect.height - dropdownMaxHeight - 8) }
                }
                positioning = {
                    ...positioning,
                    left: Math.max(
                        8,
                        Math.min(elemRect.left, dropdownWidth ? bodyRect.width - dropdownWidth - 8 : elemRect.left)
                    ),
                }
            } else {
                if (dropdownMaxHeight + bottomPadding > positionFromBottom) {
                    positioning = { bottom: inputRef.current.clientHeight - 8, top: 'unset' }
                } else {
                    positioning = {
                        top: inputRef.current.clientHeight + 8,
                        bottom: 'unset',
                    }
                }
            }

            props = {
                ...props,
                position: fixedPosition ? 'fixed' : 'absolute',
                width: dropdownWidth || inputRef.current.clientWidth,
                maxWidth: bodyRect.width - 16,
                ...positioning,

                maxHeight: dropdownMaxHeight,
                zIndex: 9999,
            }
        }
        return props
    }, [dropdownMaxHeight, dropdownWidth, fixedPosition, inputRef, style, theme])

    return (
        <DropDownOptionListUl {...rest} style={dropdownStyle} ref={ref}>
            {children}
        </DropDownOptionListUl>
    )
})

export const StyledInput = withTheme(styled(Input)`
    &.open {
        border: 1px solid ${fromTheme('Primary_700')};
        outline: none;
        box-shadow: 0 0 0 4px rgba(55, 114, 255, 0.3);
    }
`)

export const StyledFontAwesomeIcon = withTheme(
    styled(FontAwesomeIcon)(({ theme }: { theme: AdminTheme }) => ({
        position: 'absolute',
        right: '12px',
        cursor: 'pointer',
        color: theme.Neutral_700,
        fontSize: '14px',
    }))
)
