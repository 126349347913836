import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'

export const AdminBannerContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        padding: `${theme.Spacing(2)} ${theme.Spacing(3)}`,
        alignSelf: 'stretch',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.BorderPrimary,
    }))
)
