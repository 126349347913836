import { useEffect } from 'react'

export function useWindowSize(handler: (windowSize: { width: number; height: number }) => void) {
    useEffect(() => {
        const handleResize = () => {
            const newWindowSize = {
                width: window.innerWidth,
                height: window.innerHeight,
            }
            handler(newWindowSize)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [handler])
}
