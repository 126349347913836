import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FC, useCallback, useState } from 'react'
import { useToast } from '../../../../hooks/use-toast'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { faSpinner, faRocket, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { Trans, useTranslation } from 'react-i18next'
import { Column } from '../../../../components/admin/layout/Column'
import { Flex } from '../../../../components/helpers/Flex'
import { LightButton } from '../../../../components/ui-kit/button/LightButton'
import { CardContainer } from '../../../../components/ui-kit/card/CardContainer'
import { Icon } from '../../../../components/ui-kit/comopnents/Icon'
import { InputGroup } from '../../../../components/ui-kit/comopnents/InputGroup'
import { RequiredIndicator } from '../../../../components/ui-kit/comopnents/RequiredIndicator'
import { AdminTheme } from '../../../../theme/theme'
import { BillingDetails, BillingDetailsForm } from '../main/BillingDetailsForm'
import { Summary } from './Summary'
import { SubscriptionBreakpoint, SubscriptionBreakpointMedia } from './SubscriptionCheckout'
import { BillingCycle, Business, SubscriptionTier } from '../../../../store/auth/types'
import { useMatchMedia } from '../../../../hooks/use-match-media'
import { PaymentStartDto, useSubscriptionApi } from '../../../../hooks/apis/use-subscription-api'
import { useBillingDetailsApi } from '../../../../hooks/apis/use-billing-details-api'
import { useSetBusiness } from '../../../../hooks/use-business'
import { InvoiceStatus } from '../main/InvoiceHistory'
import { StripeError } from '@stripe/stripe-js'

const StyledCardContainer = withTheme(
    styled(CardContainer)(({ theme }: { theme: AdminTheme }) => ({
        maxWidth: '100%',
        padding: theme.Spacing(4),
    }))
)

const CheckoutContainer = styled.div(({ paymentDetailsOpen }: { paymentDetailsOpen: boolean }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    '.first-col': {
        width: '100%',
    },
    '.second-col': {
        width: '100%',
    },
    [SubscriptionBreakpoint]: {
        '.first-col': {
            display: 'inline-flex',
            width: 'calc(100% - 372px)',
        },
        '.second-col': {
            display: 'inline-flex',
            width: '372px',
        },
    },
}))

const BillingDetailsContainer = withTheme(
    styled(Column)(({ theme }: { theme: AdminTheme }) => ({
        maxWidth: '100%',
        [SubscriptionBreakpoint]: {
            paddingRight: theme.Spacing(4),
            borderRight: `1px solid ${theme.Neutral_300}`,
        },
    }))
)

const FlexLabel = withTheme(
    styled.label(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.Spacing(1),

        input: {
            margin: 0,
            width: 16,
            height: 16,
        },
    }))
)

export const StripeCheckoutForm: FC<{
    business: Business
    billingAddress: BillingDetails
    updating: boolean
    priceInfo: PaymentStartDto | undefined
    billingCycle: BillingCycle
    couponCode: string | undefined
    setBillingCycle: (billingCycle: BillingCycle) => void
    setCouponCode: (value: string | undefined) => void
    setBillingAddress: (value: BillingDetails) => void
    onSuccess: (subscriptionTier: SubscriptionTier) => void
}> = ({
    business,
    billingAddress,
    updating,
    priceInfo,
    billingCycle,
    couponCode,
    setBillingCycle,
    setCouponCode,
    setBillingAddress,
    onSuccess,
}) => {
    const stripe = useStripe()
    const elements = useElements()
    const toast = useToast()

    const [submitting, setSubmitting] = useState(false)
    const [paymentDetailsOpen, setPaymentDetailsOpen] = useState(false)

    const onSetBillingCycle = useCallback(
        (value: BillingCycle) => {
            setBillingCycle(value)
            setCouponCode('')
        },
        [setBillingCycle, setCouponCode]
    )
    const billingDetailsApi = useBillingDetailsApi()

    const { finalizePayment } = useSubscriptionApi()
    const updateBusiness = useSetBusiness()
    const { t } = useTranslation('admin')

    const checkResult = useCallback(async () => {
        if (!priceInfo?.invoiceId) {
            toast.error(t('Something went wrong, please try again later.'))
            return
        }
        let response = await finalizePayment(priceInfo?.invoiceId)
        if (response.data.status !== InvoiceStatus.Successful) {
            setTimeout(checkResult, 2000)
        } else {
            updateBusiness(response.data)

            if (onSuccess) {
                onSuccess(response.data.subscriptionTier)
            }
        }
    }, [finalizePayment, onSuccess, priceInfo?.invoiceId, t, toast, updateBusiness])

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault()
            if (!stripe || !elements) {
                return
            }

            setSubmitting(true)

            await billingDetailsApi.save(billingAddress)

            let error: StripeError | undefined
            if (priceInfo?.price === 0) {
                const result = await stripe.confirmSetup({
                    //`Elements` instance that was used to create the Payment Element
                    elements,
                    confirmParams: {},
                    redirect: 'if_required',
                })
                error = result.error
            } else {
                const result = await stripe.confirmPayment({
                    //`Elements` instance that was used to create the Payment Element
                    elements,
                    confirmParams: {},
                    redirect: 'if_required',
                })
                error = result.error
            }
            if (error) {
                toast.error(error?.message || t('Something went wrong, please try again later.'))
                setSubmitting(false)
            } else {
                await checkResult()
            }

            setSubmitting(false)
        },
        [billingAddress, billingDetailsApi, checkResult, elements, priceInfo?.price, stripe, t, toast]
    )

    const isMobileView = !useMatchMedia(SubscriptionBreakpointMedia)
    const isTrial = business.subscriptionTier === 'free' && business.proTrialAvailable

    return (
        <form onSubmit={handleSubmit} className="contents">
            <StyledCardContainer className="p" id="subscription-checkout">
                <CheckoutContainer paymentDetailsOpen={paymentDetailsOpen}>
                    <BillingDetailsContainer className="first-col">
                        <h3 className="large semibold">
                            <Trans ns="admin">Billing details</Trans>
                        </h3>
                        <p>
                            {business.proTrialAvailable ? (
                                <>
                                    <Trans ns="admin">
                                        You'll <b>not be charged</b> until your free trial ends.
                                    </Trans>{' '}
                                    <Trans ns="admin">
                                        You can <b>cancel anytime</b> during your trial.
                                    </Trans>{' '}
                                    <Trans ns="admin">
                                        We'll <b>remind you 7 days before</b> your trial ends.
                                    </Trans>
                                </>
                            ) : (
                                <>
                                    👉&nbsp;
                                    <Trans ns="admin">
                                        Make sure your billing details are correct. We'll use this info to generate an
                                        invoice for you.
                                    </Trans>
                                </>
                            )}
                        </p>
                        <BillingDetailsForm
                            value={billingAddress as any}
                            onChange={(values) => setBillingAddress(values)}
                        />
                        {isMobileView ? null : paymentDetailsOpen ? (
                            <PaymentElement />
                        ) : (
                            <Flex className="w100" flexDirection="column" alignItems="stretch" gap={3}>
                                <LightButton
                                    variant="Pro"
                                    type="button"
                                    onClick={() => setPaymentDetailsOpen(true)}
                                    disabled={updating}
                                >
                                    <Icon className="mrs" icon={faCreditCard} />
                                    <Trans ns="admin">Add payment details</Trans>
                                </LightButton>
                            </Flex>
                        )}
                    </BillingDetailsContainer>
                    {priceInfo && (business.subscriptionTier === 'free' || business.isCancelPending) ? (
                        <Summary
                            loading={updating}
                            priceInfo={priceInfo}
                            couponCode={couponCode}
                            setCouponCode={setCouponCode}
                            billingCycle={billingCycle}
                            setBillingCycle={onSetBillingCycle}
                            paymentDetailsOpen={paymentDetailsOpen}
                            cardValid={true}
                            submitting={submitting}
                            updating={updating}
                            business={business}
                            targetSubscription={isTrial ? 'pro-trial' : 'pro'}
                        />
                    ) : null}
                    {isMobileView ? (
                        paymentDetailsOpen ? (
                            <Flex className="w100" my={3}>
                                <PaymentElement />
                            </Flex>
                        ) : (
                            <Flex className="w100 mt" flexDirection="column" alignItems="stretch" gap={3}>
                                <LightButton
                                    variant="Pro"
                                    type="button"
                                    onClick={() => setPaymentDetailsOpen(true)}
                                    disabled={updating}
                                >
                                    <Icon className="mrs" icon={faCreditCard} />
                                    <Trans ns="admin">Add payment details</Trans>
                                </LightButton>
                            </Flex>
                        )
                    ) : null}
                    {isMobileView && paymentDetailsOpen ? (
                        <Column>
                            <InputGroup>
                                <FlexLabel style={{ fontWeight: 'normal', fontSize: '16px' }}>
                                    <input type="checkbox" required />
                                    <div>
                                        <Trans ns="admin">
                                            I have read and accept the{' '}
                                            <a target="_blank" rel="noreferrer" href={t('privacy_policy_url')}>
                                                Privacy Policy
                                            </a>{' '}
                                            and{' '}
                                            <a target="_blank" rel="noreferrer" href={t('terms_and_conditions_url')}>
                                                General Terms & Conditions
                                            </a>
                                        </Trans>
                                        <RequiredIndicator />
                                    </div>
                                </FlexLabel>
                            </InputGroup>
                            <LightButton variant="Pro" disabled={submitting || updating || !stripe || !elements}>
                                {submitting ? (
                                    <>
                                        <Icon className="mrs" spin icon={faSpinner} />
                                        <Trans ns="admin">Processing...</Trans>
                                    </>
                                ) : business.subscriptionTier === 'pro' && business.isCancelPending ? (
                                    <>
                                        <Icon className="mrs" icon={faRocket} />
                                        <Trans ns="admin">Renew</Trans>
                                    </>
                                ) : business.proTrialAvailable ? (
                                    <>
                                        <Icon className="mrs only-desktop" icon={faRocket} />
                                        <Trans ns="admin">Start free trial</Trans>
                                    </>
                                ) : (
                                    <>
                                        <Icon className="mrs" icon={faRocket} />
                                        <Trans ns="admin">Upgrade</Trans>
                                    </>
                                )}
                            </LightButton>
                        </Column>
                    ) : null}
                </CheckoutContainer>
            </StyledCardContainer>
        </form>
    )
}
