export function insensitiveContains(haystack: string, needle: string) {
    const normalizedHaystack = haystack
        .normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    const normalizeNeedle = needle
        .normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()

    return normalizedHaystack.includes(normalizeNeedle)
}
