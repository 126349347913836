import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FieldProps } from 'formik'
import { FC, forwardRef, HtmlHTMLAttributes } from 'react'
import { classNames } from '../../../services/class-names'
import { fromTheme } from '../../../theme/theme'
import { Flex } from '../../helpers/Flex'
import { Trans } from 'react-i18next'

function asInput<Comp>(component: Comp): Comp {
    // @ts-ignore
    return withTheme(styled(component)`
        border-radius: 8px;
        border: 1px solid ${fromTheme('BroderSecondary')};
        padding: 8px 12px;

        ${fromTheme('DefaultFontFamily')};
        ${fromTheme('BodyMedium')}

        color: ${fromTheme('ContentPrimary')};
        background-color: ${fromTheme('SurfaceColor')};

        flex-grow: 1;
        flex-shrink: 1;

        min-width: 0;

        &:focus-visible {
            border: 1px solid ${fromTheme('ThemeColor')};
            outline: none;
            box-shadow: 0 0 0 4px ${fromTheme('FocusOutline')};
        }

        &.warning {
            border: 1px solid ${fromTheme('ContentWarning')};

            &:focus-visible {
                border: 1px solid ${fromTheme('ContentWarning')};
                outline: none;
                box-shadow: 0 0 0 4px ${fromTheme('ContentWarning')}4C;
            }
        }

        &.error,
        &.dirty:invalid {
            border: 1px solid ${fromTheme('Secondary4_700')};

            &:focus-visible {
                border: 1px solid ${fromTheme('Secondary4_700')};
                outline: none;
                box-shadow: 0 0 0 4px ${fromTheme('Secondary4_700')}4C;
            }
        }

        &.disabled,
        &:disabled {
            border: 1px solid ${fromTheme('Neutral_500')};
            background: ${fromTheme('BackgroundNeutralSecondary')};
            outline: none;
            box-shadow: none;
        }

        &.read-only,
        &:not(div):read-only {
            border: 1px solid ${fromTheme('Neutral_500')};
            background: ${fromTheme('Neutral_100')};
            outline: none;
            box-shadow: none;
            cursor: default;
        }

        ::placeholder {
            color: ${fromTheme('Neutral_500')};
        }

        &[type='radio'],
        &[type='checkbox'] {
            flex-grow: 0;
            flex-shrink: 0;
            margin: 0;
            width: 16px;
            height: 16px;
            accent-color: ${fromTheme('ThemeColor')};
            &:focus-visible {
                box-shadow: 0 0 0 2px ${fromTheme('FocusOutline')};
            }
        }
    `)
}

export const MaxLengthInputHelper: FC<{ maxLength: number; text: string | undefined }> = ({ maxLength, text }) => {
    return (
        <Flex className="caption subtle" justifyContent="space-between">
            <span>
                <Trans ns="admin" values={{ maxLength }}>
                    Max. {{ maxLength }} characters
                </Trans>
            </span>
            <span>
                {text?.length || '0'}/{maxLength}
            </span>
        </Flex>
    )
}

export const Input = asInput(styled.input``)

export const InputlikeDiv = asInput(
    styled.div(() => ({
        cursor: 'pointer',
        minHeight: 42,
    }))
)

export const FormikInput = forwardRef<any, FieldProps & HtmlHTMLAttributes<HTMLInputElement>>(
    ({ field, form, className, ...props }, ref) => {
        const meta = form.getFieldMeta(field.name)
        return (
            <Input
                className={classNames(className, meta?.error ? 'error' : undefined)}
                {...field}
                {...props}
                ref={ref as any}
            />
        )
    }
)
