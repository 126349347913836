import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, ReactNode, useEffect } from 'react'
import { AdminTheme } from '../../../theme/theme'
import { TextButton } from '../../../components/ui-kit/button/TextButton'
import { Icon } from '../../../components/ui-kit/comopnents/Icon'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Trans } from 'react-i18next'

const FooterContainer = withTheme(
    styled.div(
        ({
            theme,
            forceSpaceBetween,
            hideBackButton,
        }: {
            theme: AdminTheme
            forceSpaceBetween?: boolean
            hideBackButton?: boolean
        }) => ({
            position: 'sticky',
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            padding: theme.Spacing(2.5),
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: theme.BorderPrimary,
            backgroundColor: theme.SurfaceColor,
            gap: theme.Spacing(2),
            '.continue': {
                flexGrow: 1,
            },

            justifyContent: forceSpaceBetween ? 'space-between' : undefined,

            [theme.BreakPoint]: {
                padding: theme.Spacing(3),
                justifyContent: hideBackButton ? 'flex-end' : 'space-between',
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                '.continue': {
                    flexGrow: 0,
                },
            },
        })
    )
)
export const Footer: FC<{
    nextButton: ReactNode
    summary?: ReactNode
    forceSpaceBetween?: boolean
    scrollOnAppear?: boolean
    back?: () => void
    hideBackButton?: boolean
}> = ({ nextButton, summary, forceSpaceBetween, scrollOnAppear, back, hideBackButton }) => {
    useEffect(() => {
        if (scrollOnAppear) {
            document.getElementById('booking-page-footer')?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [scrollOnAppear])

    return (
        <FooterContainer
            id="booking-page-footer"
            hideBackButton={hideBackButton}
            forceSpaceBetween={hideBackButton ? undefined : forceSpaceBetween}
        >
            {back ? (
                !hideBackButton ? (
                    <TextButton onClick={back} className="only-desktop">
                        <Icon icon={faChevronLeft} className="mrs" />
                        <Trans ns="bookingpage">Back</Trans>
                    </TextButton>
                ) : null
            ) : (
                summary
            )}
            {nextButton}
        </FooterContainer>
    )
}
