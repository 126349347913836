import { withTheme } from '@emotion/react'
import styled, { CreateStyledComponent } from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { FaContainer } from '../comopnents/Icon'
import { NavLink } from 'react-router-dom'

function asTextButton<ComponentProps extends {}, SpecificComponentProps extends {}>(
    component: CreateStyledComponent<ComponentProps, SpecificComponentProps>
) {
    return withTheme(
        component(({ theme }: { theme: AdminTheme }) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none',
            paddingLeft: 0,
            background: 'none',
            cursor: 'pointer',
            color: theme.InteractivePrimary,
            '&:hover': {
                color: theme.InteractivePrimaryHover,
            },
            '&:active': {
                color: theme.InteractivePrimaryActive,
            },
            '&.danger': {
                color: theme.Secondary4_700,
                '&:hover': {
                    color: theme.Secondary4_800,
                },
                '&:active': {
                    color: theme.Secondary4_900,
                },
            },
            '&.neutral': {
                color: theme.ContentTertiary,
                '&:hover': {
                    color: theme.ContentSecondary,
                },
                '&:active': {
                    color: theme.ContentPrimary,
                },
            },
            '&:disabled': {
                color: theme.InteractiveDisabledDefault,
            },
            ...theme._BodyMedium,
            [FaContainer as any]: {
                height: '22px',
                width: 'auto',
                marginRight: theme.Spacing(1),
                '&.end-icon': {
                    marginLeft: theme.Spacing(1),
                },
            },
        }))
    )
}

export const TextButton = asTextButton(styled.button)

export const TextButtonLink = asTextButton(styled.a)

export const TextButtonNavLink = asTextButton(styled(NavLink))

export const TextButtonSpan = asTextButton(styled.span)
